<template>
  <form class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Gerar Documento</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <e-row mr>
        <e-col>
          <erp-s-field label="Qual a finalidade da emissão?">
            <erp-select placeholder="Selecione" v-model="doc.finalidade" :options="finalidades"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="Qual o documento deseja emitir?">
            <documento-input v-model="doc.documento" :finalidade="doc.finalidade" @changeDocumento="changeDocumento"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="ID(s) no sistema:">
            <erp-input v-model="doc.ids"/>
            <div class="font-10 m-t-xxs">Para mais de um, separe-os por vírgula</div>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" mr>
        <e-col>
          <erp-s-field label="Qual o formato deseja imprimir?">
            <erp-select :disable="!doc.finalidade" placeholder="Selecione" v-model="doc.formato" :options="formatos"/>
            <div class="font-10 m-t-xxs">Nem sempre o documento estará disponível no formato</div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn
            @click="gerar"
            :loading="loading" type="button" label="Emitir" no-caps
            :color="'green'"
            class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>
  </form>
</template>

<script>

import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import {finalidades, formatos} from "@/domain/documento/helpers/helpers"
import DocumentoInput from "components/layout/components/gerador/DocumentoInput.vue"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import {gerar} from "@/domain/documento/services"
import {donwloadFile} from "@/utils/downloadFile"
import createPrint from "components/relatorios/components/customPrint/createPrint"

export default {
  name: 'GeradorDocumentoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  components: {
    ErpInput,
    DocumentoInput,
    ECol,
    ERow,
    ErpSelect,
    ErpSField
  },
  props: {
    id: null,
    options: null,
    router: null,
    finalidade: null,
    ids: null,
    documento: null,
    formato: null
  },
  data() {
    return {
      loading: false,
      cache: {
        documento: null
      },
      doc: {
        finalidade: this.finalidade,
        ids: this.ids,
        formato: this.formato
      }
    }
  },
  computed: {
    finalidades() {
      return finalidades
    },
    formatos() {
      return formatos.filter(f => {
        if (!this.cache.documento || !this.cache.documento.config || !this.cache.documento.config.formatos || !this.cache.documento.config.formatos.length) {
          return true
        }
        return this.cache.documento.config.formatos.includes(f.value)
      })
    }
  },
  mounted() {
  },
  methods: {
    changeDocumento(d) {
      this.cache.documento = d
    },
    createPrint: createPrint,
    gerar() {
      if (!this.cache.documento) {
        this.dg('Erro de validação', 'Selecione um documento para ser gerado', 'Ok', 'c-negative')
        return
      }
      console.log('Gerando no formato ', this.doc.formato)
      this.$uloc.loading.show()
      gerar(this.cache.documento.id, this.doc, this.doc.formato)
          .then(response => {
            this.$uloc.loading.hide()
            if (this.doc.formato === 'word' || this.doc.formato === 'pdf' || this.doc.formato === 'excel') {
              donwloadFile(response)
              return
            }
            if (this.doc.formato === 'print') {
              this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint.vue'), 'gerencial', this.cache.documento.name, this.cache.documento.orientation || 'portrait')
              return
            }
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>
