export const finalidades = [
    {label: 'Leilão', value: 'leilao'},
    {label: 'Bem', value: 'bem'},
    {label: 'Lote', value: 'lote'},
    {label: 'Arrematante', value: 'arrematante'},
    {label: 'Vistoria', value: 'vistoria'},
    {label: 'Conta a Pagar/Receber', value: 'financeiro'},
    {label: 'Comitente', value: 'comitente'},
    {label: 'Tarefa', value: 'tarefa'},
    {label: 'Processo', value: 'processo'},
    {label: 'Pessoa', value: 'pessoa'},
    {label: 'Remoção', value: 'remocao'},
]

export const formatos = [
    {label: 'Word', value: 'word'},
    {label: 'PDF', value: 'pdf'},
    {label: 'Excel', value: 'excel'},
    {label: 'Impressão', value: 'print'},
]
